import { ListBulletIcon } from "@heroicons/react/16/solid";
import { CalendarDaysIcon, MegaphoneIcon, TvIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { APP_BOX_MEMBERSHIPS, APP_WHITEBOARD } from "../_constants/permissions";
import { useMyself } from "../_context/UserContext";
import { classNames } from "../_helpers";

type PropTypes = {
  noUser?: boolean
}

export const AppNav = ({ noUser }: PropTypes) => {
  const { canAccess, boxOperator, profileImageUrl, dashboardBadgeCount } = useMyself() || {}

  const navigation = useMemo(() => ([
    {
      title: 'Whiteboard',
      link: '/whiteboard',
      icon: TvIcon,
      permissions: [APP_WHITEBOARD]
    },
    {
      title: 'Übungen',
      link: '/exercises',
      icon: ListBulletIcon,
      permissions: null
    },
    {
      title: 'Workout',
      link: '/workout',
      icon: CalendarDaysIcon,
      permissions: null
    },
    {
      title: 'Mitglieder',
      link: '/box-members',
      icon: UserGroupIcon,
      permissions: [APP_BOX_MEMBERSHIPS],
      badge: boxOperator?.requestedMembersAmount ? boxOperator.requestedMembersAmount : null
    }
  ]), [boxOperator?.requestedMembersAmount])

  const dashboardBadgeTotalCount = useMemo(() => {
    let count = (dashboardBadgeCount || 0)

    if (!profileImageUrl) {
      count++
    }

    const isPWA = window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any)?.navigator?.standalone === true ||
      document.referrer.includes('android-app://')

    if (!isPWA) {
      count++
    }

    return count
  }, [dashboardBadgeCount, profileImageUrl])

  if (!canAccess || noUser) {
    return null
  }

  return (
    <div
      className="fixed bottom-0 left-0 z-50 w-full pb-safe bg-neutral-900/80 backdrop-blur border-t border-neutral-700 shaddow">
      <div className="flex h-full max-w-lg mx-auto font-medium">
        {(!canAccess || !canAccess(APP_WHITEBOARD)) && (
          <NavLink
            to={'/dashboard'}
            className={({isActive, isPending}) => classNames(
              "relative inline-flex flex-col items-center justify-center flex-grow px-5 py-3 hover:bg-neutral-900/50 group",
            )}
          >
            {({isActive, isPending, isTransitioning}) => (
              <>
                <MegaphoneIcon className={classNames(
                  'w-5 h-5 mb-2  group-hover:text-sky-400',
                  isActive || isPending ? 'text-sky-400' : 'text-neutral-500'
                )}/>
                <span className={classNames(
                  'text-xs group-hover:text-white/70',
                  isActive || isPending ? 'text-white' : 'text-neutral-500'
                )}>
                  Dashboard
                  {dashboardBadgeTotalCount > 0 && (
                    <span
                      className="absolute top-2 left-1/2 translate-x-2/3 w-[18px] h-[18px] aspect-square inline-flex items-center rounded-full bg-red-500/10 px-1.5 py-0.5 text-[10px] font-bold text-red-500 ring-1 ring-inset ring-red-500">
                        {dashboardBadgeTotalCount}
                      </span>
                  )}
                </span>
              </>
            )}
          </NavLink>
        )}
        {navigation.map(item => {
          if (item.permissions && !item.permissions.reduce((acc, item) => acc || canAccess(item), false)) {
            return null
          }

          return (
            <NavLink
              key={item.link}
              to={item.link}
              className={({isActive, isPending}) => classNames(
                "relative inline-flex flex-col items-center justify-center flex-grow px-5 py-3 hover:bg-neutral-900/50 group",
              )}
            >
              {({isActive, isPending, isTransitioning}) => (
                <>
                  <item.icon className={classNames(
                    'w-5 h-5 mb-2  group-hover:text-sky-400',
                    isActive || isPending ? 'text-sky-400' : 'text-neutral-500'
                  )}/>
                  <span className={classNames(
                    'text-xs group-hover:text-white/70',
                    isActive || isPending ? 'text-white' : 'text-neutral-500'
                  )}>
                    {item.title}
                    {item?.badge && (
                      <span
                        className="absolute top-2 left-1/2 translate-x-2/3 w-[18px] h-[18px] aspect-square inline-flex items-center rounded-full bg-red-500/10 px-1.5 py-0.5 text-[10px] font-bold text-red-500 ring-1 ring-inset ring-red-500">
                        {item?.badge}
                      </span>
                    )}
                  </span>
                </>
              )}
            </NavLink>
          )
          }
        )}
      </div>
    </div>
  )
}
