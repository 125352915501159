/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  fragment WorkoutBlockFragment on WorkoutBlock {\n    id\n    title\n    rounds\n    measurementType\n    measurementExercise {\n      id\n      title\n    }\n    presets {\n      id\n      sort\n      presetType\n      presetValue\n      measurementType\n      variant\n      exercise {\n        id\n        title\n      }\n    }\n  }\n": types.WorkoutBlockFragmentFragmentDoc,
    "\n  fragment ExerciseResultFragment on ExerciseResult {\n    id\n    date\n    rounds\n    repetitions\n    weight\n    weightUnit\n    type\n    barWeight\n    selectedPlates\n    distance\n    calories\n    variant\n    height\n    time\n    notice\n    athletID\n    presetID\n    workoutID\n    workoutBlockID\n    isWorkoutResult\n    exercise {\n      id\n      title\n    }\n  }\n": types.ExerciseResultFragmentFragmentDoc,
    "\n  mutation RegisterMember($input: RegisterMemberInput!) {\n    registerMember(input: $input)\n  }\n": types.RegisterMemberDocument,
    "\n  mutation SetNewPassword($input: SetNewPasswordInput!) {\n    setNewPassword(input: $input) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n": types.SetNewPasswordDocument,
    "\n  mutation LoginUser($input: LoginUserInput!) {\n    loginUser(input: $input) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n": types.LoginUserDocument,
    "\n  mutation RefreshToken($refresh_token: String!) {\n    refreshToken(refresh_token: $refresh_token) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n": types.RefreshTokenDocument,
    "\n  mutation LogoutUser {\n    logoutUser\n  }\n": types.LogoutUserDocument,
    "\n  mutation UpdateProfileImage($input: UpdateProfileImageInput!) {\n    updateProfileImage(input: $input) {\n      id\n      profileImageUrl\n    }\n  }\n": types.UpdateProfileImageDocument,
    "\n  mutation AddResult($input: AddResultInput!) {\n    addResult(input: $input) {\n      exerciseResult {\n        ...ExerciseResultFragment\n      }\n      athlet {\n        id\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n": types.AddResultDocument,
    "\n  mutation DeleteResult($input: DeleteResultInput!) {\n    deleteResult(input: $input) {\n      id\n      bestResult\n      lastTraining\n      exerciseResults {\n        ...ExerciseResultFragment\n      }\n    }\n  }\n": types.DeleteResultDocument,
    "\n  mutation AddAthlet($input: AddAthletInput!) {\n    addAthlet(input: $input) {\n      id\n      teammates {\n        id\n        name\n        editable\n      }\n    }\n  }\n": types.AddAthletDocument,
    "\n  mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {\n    createCheckoutSession(input: $input)\n  }\n": types.CreateCheckoutSessionDocument,
    "\n  mutation CreateBoxCheckoutSession {\n    createBoxCheckoutSession\n  }\n": types.CreateBoxCheckoutSessionDocument,
    "\n  mutation CreateBillingPortalSession {\n    createBillingPortalSession\n  }\n": types.CreateBillingPortalSessionDocument,
    "\n  mutation CreateBoxBillingPortalSession {\n    createBoxBillingPortalSession\n  }\n": types.CreateBoxBillingPortalSessionDocument,
    "\n  mutation UpdateSubscriptionStatus {\n    updateSubscriptionStatus {\n      id\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n      boxOwnership {\n        id\n        hasActiveSubscription\n      }\n    }\n  }\n": types.UpdateSubscriptionStatusDocument,
    "\n  mutation JoinBox($input: JoinBoxInput!) {\n    joinBox(input: $input) {\n      id\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n    }\n  }\n": types.JoinBoxDocument,
    "\n  mutation ConfirmBoxMembership($input: ConfirmBoxMembershipInput!) {\n    confirmBoxMembership(input: $input) {\n      id\n      displayTitle\n      requestedMembersAmount\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n": types.ConfirmBoxMembershipDocument,
    "\n  mutation CancelBoxMembership($input: CancelBoxMembershipInput!) {\n    cancelBoxMembership(input: $input) {\n      id\n      displayTitle\n      requestedMembersAmount\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n": types.CancelBoxMembershipDocument,
    "\n  mutation AddWorkout($input: AddWorkoutInput!) {\n    addWorkout(input: $input) {\n      id\n      public {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        measurementType\n        measurementExercise {\n          id\n        }\n        customer {\n          id\n          title\n        }\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n      private {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        measurementType\n        measurementExercise {\n          id\n        }\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n    }\n  }\n": types.AddWorkoutDocument,
    "\n  mutation AddWorkoutToAiFineTuning($input: AddWorkoutInput!) {\n    addWorkoutToAiFineTuning(input: $input) {\n      id\n      canBeUsedForFineTuning\n    }\n  }\n": types.AddWorkoutToAiFineTuningDocument,
    "\n  mutation AddAiWorkout($input: AddAiWorkoutInput!) {\n    addAiWorkout(input: $input) {\n      id\n      public {\n        id\n        title\n        description\n        date\n        allowExerciseTracking\n        canBeUsedForFineTuning\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        customer {\n          id\n          title\n        }\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n      private {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n    }\n  }\n": types.AddAiWorkoutDocument,
    "\n  mutation DeleteWorkout($input: DeleteWorkoutInput!) {\n    deleteWorkout(input: $input) {\n      id\n      public {\n        id\n      }\n      private {\n        id\n      }\n    }\n  }\n": types.DeleteWorkoutDocument,
    "\n  mutation ResetPassword($input: ResetPasswordInput!) {\n    resetPassword(input: $input)\n  }\n": types.ResetPasswordDocument,
    "\n  mutation AdoptAthlet($input: AdoptAthletInput!) {\n    adoptAthlet(input: $input) {\n      id\n      date\n      workoutBlocks {\n        id\n        presets {\n          id\n        }\n      }\n      teammates {\n        id\n        name\n        adoptionToken\n        editable\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n": types.AdoptAthletDocument,
    "\n  mutation CreateClassParticipation($input: CreateClassParticipationInput!) {\n    createClassParticipation(input: $input) {\n      message\n      workoutClass {\n        id\n        potentialAthletes {\n          id\n          firstName\n          surname\n        }\n        classAthletes {\n          id\n          name\n          memberID\n          workoutID\n          member {\n            id\n            firstName\n            surname\n          }\n          exerciseResults {\n            ...ExerciseResultFragment\n          }\n        }\n      }\n    }\n  }\n": types.CreateClassParticipationDocument,
    "\n  mutation CancelClassParticipation($input: CancelClassParticipationInput!) {\n    cancelClassParticipation(input: $input) {\n      id\n      potentialAthletes {\n        id\n        firstName\n        surname\n      }\n      classAthletes {\n        id\n      }\n    }\n  }\n": types.CancelClassParticipationDocument,
    "\n  mutation StartWorkoutClass($input: StartWorkoutClassInput!) {\n    startWorkoutClass(input: $input) {\n      id\n      title\n      date\n    }\n  }\n": types.StartWorkoutClassDocument,
    "\n  mutation EditWorkoutClass($input: EditWorkoutClassInput!) {\n    editWorkoutClass(input: $input) {\n      id\n      title\n      date\n      workouts {\n        id\n        title\n        workoutType\n        workoutBlocks {\n          id\n          title\n          presets {\n            id\n            presetType\n            presetValue\n            exercise {\n              id\n            }\n          }\n        }\n      }\n      classAthletes {\n        id\n        name\n        member {\n          id\n          firstName\n          surname\n        }\n        memberID\n        workoutID\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n": types.EditWorkoutClassDocument,
    "\n  mutation MarkBlogEntryAsRead($input: MarkBlogEntryAsReadInput!) {\n    markBlogEntryAsRead(input: $input) {\n      id\n      isNew\n    }\n  }\n": types.MarkBlogEntryAsReadDocument,
    "\n  query ReadMyself {\n    readMyself {\n      id\n      firstName\n      surname\n      email\n      profileImageUrl\n      boxOwnership {\n        id\n        displayTitle\n        hasActiveSubscription\n      }\n      boxOperator {\n        id\n        displayTitle\n        hasActiveSubscription\n        requestedMembersAmount\n      }\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n      boxMemberships {\n        id\n        displayTitle\n      }\n      permissions\n      dashboardBadgeCount\n      streak {\n        id\n        numberOfDays\n        streakWeeks\n        upcomingStreakDays\n        upcomingStreakWeeks\n      }\n    }\n  }\n": types.ReadMyselfDocument,
    "\n  query ReadExercises {\n    readExercises {\n      id\n      title\n      bestResult\n      lastTraining\n      type\n    }\n  }\n": types.ReadExercisesDocument,
    "\n  query ReadOneExercise($input: ReadOneExerciseInput!) {\n    readOneExercise(input: $input) {\n      id\n      title\n      bestResult\n      lastTraining\n      type\n      exerciseResults {\n        ...ExerciseResultFragment\n      }\n    }\n  }\n": types.ReadOneExerciseDocument,
    "\n  query ReadOneExerciseResult($input: ReadOneExerciseResultInput!) {\n    readOneExerciseResult(input: $input) {\n      ...ExerciseResultFragment\n    }\n  }\n": types.ReadOneExerciseResultDocument,
    "\n  query ReadWorkoutsByDate($input: ReadWorkoutsByDateInput!) {\n    readWorkoutsByDate(input: $input) {\n      id\n      public {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        canBeUsedForFineTuning\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        customer {\n          id\n          displayTitle\n        }\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n      private {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n    }\n  }\n": types.ReadWorkoutsByDateDocument,
    "\n  query ReadOneWorkout($input: ReadOneWorkoutInput!) {\n    readOneWorkout(input: $input) {\n      id\n      title\n      description\n      date\n      workoutType\n      allowExerciseTracking\n      measurementExercise {\n        id\n        title\n      }\n      measurementType\n      workoutBlocks {\n        ...WorkoutBlockFragment\n      }\n      teammates {\n        id\n        name\n        adoptionToken\n        editable\n        isMyself\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n": types.ReadOneWorkoutDocument,
    "\n  query ReadOneWorkoutRanking($input: ReadOneWorkoutInput!) {\n    readOneWorkout(input: $input) {\n      id\n      title\n      date\n      workoutType\n      allowExerciseTracking\n      boxRanking {\n        position\n        member {\n          id\n          firstName\n          surname\n          profileImageUrl\n        }\n        athlet {\n          id\n          exerciseResults {\n            ...ExerciseResultFragment\n          }\n        }\n        score\n      }\n      workoutBlocks {\n        id\n        title\n        presets {\n          id\n\n          presetType\n          presetValue\n          variant\n          exercise {\n            id\n            title\n          }\n\n          boxRanking {\n            position\n            member {\n              id\n              firstName\n              surname\n              profileImageUrl\n            }\n            score\n          }\n        }\n      }\n    }\n  }\n": types.ReadOneWorkoutRankingDocument,
    "\n  query ReadOneAthlet($input: ReadOneAthletInput!) {\n    readOneAthlet(input: $input) {\n      id\n      name\n      adoptionToken\n      editable\n      exerciseResults {\n        ...ExerciseResultFragment\n      }\n    }\n  }\n": types.ReadOneAthletDocument,
    "\n  query ReadCustomer {\n    readCustomer {\n      id\n      displayTitle\n      description\n    }\n  }\n": types.ReadCustomerDocument,
    "\n  query ReadBoxOwnership {\n    readBoxOwnership {\n      id\n      displayTitle\n      hasActiveSubscription\n      confirmedMembers {\n        id\n        firstName\n        surname\n        profileImageUrl\n        latestActivity\n        latestBoxActivity\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n        profileImageUrl\n        latestActivity\n        latestBoxActivity\n      }\n    }\n  }\n": types.ReadBoxOwnershipDocument,
    "\n  query ReadPrices {\n    readPrices {\n      id\n      amount\n      period\n      title\n      description\n    }\n  }\n": types.ReadPricesDocument,
    "\n  query ReadContent($type: String!) {\n    readContent(type: $type)\n  }\n": types.ReadContentDocument,
    "\n  query ReadFaqItems {\n    readFaqItems {\n      id\n      title\n      content\n    }\n  }\n": types.ReadFaqItemsDocument,
    "\n  query ReadWorkoutClassesByDate($input: ReadWorkoutClassesByDateInput!) {\n    readWorkoutClassesByDate(input: $input) {\n      id\n      title\n      date\n    }\n  }\n": types.ReadWorkoutClassesByDateDocument,
    "\n  query ReadOneWorkoutClass($input: ReadOneWorkoutClassInput!) {\n    readOneWorkoutClass(input: $input) {\n      id\n      title\n      date\n      workouts {\n        id\n        title\n        workoutType\n        allowExerciseTracking\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n      potentialAthletes {\n        id\n        firstName\n        surname\n      }\n      classAthletes {\n        id\n        name\n        member {\n          id\n          firstName\n          surname\n          profileImageUrl\n        }\n        memberID\n        workoutID\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n": types.ReadOneWorkoutClassDocument,
    "\n  query ReadExistingResultsByWeek($input: ReadExistingResultsByWeekInput!) {\n    readExistingResultsByWeek(input: $input) {\n      date\n      hasResult\n    }\n  }\n": types.ReadExistingResultsByWeekDocument,
    "\n  query ReadBlogEntries {\n    readBlogEntries {\n      id\n      creator {\n        id\n        firstName\n        surname\n        profileImageUrl\n      }\n      title\n      content\n      date\n      isNew\n    }\n  }\n": types.ReadBlogEntriesDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkoutBlockFragment on WorkoutBlock {\n    id\n    title\n    rounds\n    measurementType\n    measurementExercise {\n      id\n      title\n    }\n    presets {\n      id\n      sort\n      presetType\n      presetValue\n      measurementType\n      variant\n      exercise {\n        id\n        title\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment WorkoutBlockFragment on WorkoutBlock {\n    id\n    title\n    rounds\n    measurementType\n    measurementExercise {\n      id\n      title\n    }\n    presets {\n      id\n      sort\n      presetType\n      presetValue\n      measurementType\n      variant\n      exercise {\n        id\n        title\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ExerciseResultFragment on ExerciseResult {\n    id\n    date\n    rounds\n    repetitions\n    weight\n    weightUnit\n    type\n    barWeight\n    selectedPlates\n    distance\n    calories\n    variant\n    height\n    time\n    notice\n    athletID\n    presetID\n    workoutID\n    workoutBlockID\n    isWorkoutResult\n    exercise {\n      id\n      title\n    }\n  }\n"): (typeof documents)["\n  fragment ExerciseResultFragment on ExerciseResult {\n    id\n    date\n    rounds\n    repetitions\n    weight\n    weightUnit\n    type\n    barWeight\n    selectedPlates\n    distance\n    calories\n    variant\n    height\n    time\n    notice\n    athletID\n    presetID\n    workoutID\n    workoutBlockID\n    isWorkoutResult\n    exercise {\n      id\n      title\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RegisterMember($input: RegisterMemberInput!) {\n    registerMember(input: $input)\n  }\n"): (typeof documents)["\n  mutation RegisterMember($input: RegisterMemberInput!) {\n    registerMember(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetNewPassword($input: SetNewPasswordInput!) {\n    setNewPassword(input: $input) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n"): (typeof documents)["\n  mutation SetNewPassword($input: SetNewPasswordInput!) {\n    setNewPassword(input: $input) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation LoginUser($input: LoginUserInput!) {\n    loginUser(input: $input) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n"): (typeof documents)["\n  mutation LoginUser($input: LoginUserInput!) {\n    loginUser(input: $input) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RefreshToken($refresh_token: String!) {\n    refreshToken(refresh_token: $refresh_token) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n"): (typeof documents)["\n  mutation RefreshToken($refresh_token: String!) {\n    refreshToken(refresh_token: $refresh_token) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation LogoutUser {\n    logoutUser\n  }\n"): (typeof documents)["\n  mutation LogoutUser {\n    logoutUser\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateProfileImage($input: UpdateProfileImageInput!) {\n    updateProfileImage(input: $input) {\n      id\n      profileImageUrl\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateProfileImage($input: UpdateProfileImageInput!) {\n    updateProfileImage(input: $input) {\n      id\n      profileImageUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddResult($input: AddResultInput!) {\n    addResult(input: $input) {\n      exerciseResult {\n        ...ExerciseResultFragment\n      }\n      athlet {\n        id\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddResult($input: AddResultInput!) {\n    addResult(input: $input) {\n      exerciseResult {\n        ...ExerciseResultFragment\n      }\n      athlet {\n        id\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteResult($input: DeleteResultInput!) {\n    deleteResult(input: $input) {\n      id\n      bestResult\n      lastTraining\n      exerciseResults {\n        ...ExerciseResultFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteResult($input: DeleteResultInput!) {\n    deleteResult(input: $input) {\n      id\n      bestResult\n      lastTraining\n      exerciseResults {\n        ...ExerciseResultFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddAthlet($input: AddAthletInput!) {\n    addAthlet(input: $input) {\n      id\n      teammates {\n        id\n        name\n        editable\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddAthlet($input: AddAthletInput!) {\n    addAthlet(input: $input) {\n      id\n      teammates {\n        id\n        name\n        editable\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {\n    createCheckoutSession(input: $input)\n  }\n"): (typeof documents)["\n  mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {\n    createCheckoutSession(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateBoxCheckoutSession {\n    createBoxCheckoutSession\n  }\n"): (typeof documents)["\n  mutation CreateBoxCheckoutSession {\n    createBoxCheckoutSession\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateBillingPortalSession {\n    createBillingPortalSession\n  }\n"): (typeof documents)["\n  mutation CreateBillingPortalSession {\n    createBillingPortalSession\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateBoxBillingPortalSession {\n    createBoxBillingPortalSession\n  }\n"): (typeof documents)["\n  mutation CreateBoxBillingPortalSession {\n    createBoxBillingPortalSession\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSubscriptionStatus {\n    updateSubscriptionStatus {\n      id\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n      boxOwnership {\n        id\n        hasActiveSubscription\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSubscriptionStatus {\n    updateSubscriptionStatus {\n      id\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n      boxOwnership {\n        id\n        hasActiveSubscription\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation JoinBox($input: JoinBoxInput!) {\n    joinBox(input: $input) {\n      id\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n    }\n  }\n"): (typeof documents)["\n  mutation JoinBox($input: JoinBoxInput!) {\n    joinBox(input: $input) {\n      id\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ConfirmBoxMembership($input: ConfirmBoxMembershipInput!) {\n    confirmBoxMembership(input: $input) {\n      id\n      displayTitle\n      requestedMembersAmount\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ConfirmBoxMembership($input: ConfirmBoxMembershipInput!) {\n    confirmBoxMembership(input: $input) {\n      id\n      displayTitle\n      requestedMembersAmount\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CancelBoxMembership($input: CancelBoxMembershipInput!) {\n    cancelBoxMembership(input: $input) {\n      id\n      displayTitle\n      requestedMembersAmount\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CancelBoxMembership($input: CancelBoxMembershipInput!) {\n    cancelBoxMembership(input: $input) {\n      id\n      displayTitle\n      requestedMembersAmount\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddWorkout($input: AddWorkoutInput!) {\n    addWorkout(input: $input) {\n      id\n      public {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        measurementType\n        measurementExercise {\n          id\n        }\n        customer {\n          id\n          title\n        }\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n      private {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        measurementType\n        measurementExercise {\n          id\n        }\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddWorkout($input: AddWorkoutInput!) {\n    addWorkout(input: $input) {\n      id\n      public {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        measurementType\n        measurementExercise {\n          id\n        }\n        customer {\n          id\n          title\n        }\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n      private {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        measurementType\n        measurementExercise {\n          id\n        }\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddWorkoutToAiFineTuning($input: AddWorkoutInput!) {\n    addWorkoutToAiFineTuning(input: $input) {\n      id\n      canBeUsedForFineTuning\n    }\n  }\n"): (typeof documents)["\n  mutation AddWorkoutToAiFineTuning($input: AddWorkoutInput!) {\n    addWorkoutToAiFineTuning(input: $input) {\n      id\n      canBeUsedForFineTuning\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddAiWorkout($input: AddAiWorkoutInput!) {\n    addAiWorkout(input: $input) {\n      id\n      public {\n        id\n        title\n        description\n        date\n        allowExerciseTracking\n        canBeUsedForFineTuning\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        customer {\n          id\n          title\n        }\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n      private {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddAiWorkout($input: AddAiWorkoutInput!) {\n    addAiWorkout(input: $input) {\n      id\n      public {\n        id\n        title\n        description\n        date\n        allowExerciseTracking\n        canBeUsedForFineTuning\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        customer {\n          id\n          title\n        }\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n      private {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteWorkout($input: DeleteWorkoutInput!) {\n    deleteWorkout(input: $input) {\n      id\n      public {\n        id\n      }\n      private {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteWorkout($input: DeleteWorkoutInput!) {\n    deleteWorkout(input: $input) {\n      id\n      public {\n        id\n      }\n      private {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ResetPassword($input: ResetPasswordInput!) {\n    resetPassword(input: $input)\n  }\n"): (typeof documents)["\n  mutation ResetPassword($input: ResetPasswordInput!) {\n    resetPassword(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdoptAthlet($input: AdoptAthletInput!) {\n    adoptAthlet(input: $input) {\n      id\n      date\n      workoutBlocks {\n        id\n        presets {\n          id\n        }\n      }\n      teammates {\n        id\n        name\n        adoptionToken\n        editable\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AdoptAthlet($input: AdoptAthletInput!) {\n    adoptAthlet(input: $input) {\n      id\n      date\n      workoutBlocks {\n        id\n        presets {\n          id\n        }\n      }\n      teammates {\n        id\n        name\n        adoptionToken\n        editable\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateClassParticipation($input: CreateClassParticipationInput!) {\n    createClassParticipation(input: $input) {\n      message\n      workoutClass {\n        id\n        potentialAthletes {\n          id\n          firstName\n          surname\n        }\n        classAthletes {\n          id\n          name\n          memberID\n          workoutID\n          member {\n            id\n            firstName\n            surname\n          }\n          exerciseResults {\n            ...ExerciseResultFragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateClassParticipation($input: CreateClassParticipationInput!) {\n    createClassParticipation(input: $input) {\n      message\n      workoutClass {\n        id\n        potentialAthletes {\n          id\n          firstName\n          surname\n        }\n        classAthletes {\n          id\n          name\n          memberID\n          workoutID\n          member {\n            id\n            firstName\n            surname\n          }\n          exerciseResults {\n            ...ExerciseResultFragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CancelClassParticipation($input: CancelClassParticipationInput!) {\n    cancelClassParticipation(input: $input) {\n      id\n      potentialAthletes {\n        id\n        firstName\n        surname\n      }\n      classAthletes {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CancelClassParticipation($input: CancelClassParticipationInput!) {\n    cancelClassParticipation(input: $input) {\n      id\n      potentialAthletes {\n        id\n        firstName\n        surname\n      }\n      classAthletes {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation StartWorkoutClass($input: StartWorkoutClassInput!) {\n    startWorkoutClass(input: $input) {\n      id\n      title\n      date\n    }\n  }\n"): (typeof documents)["\n  mutation StartWorkoutClass($input: StartWorkoutClassInput!) {\n    startWorkoutClass(input: $input) {\n      id\n      title\n      date\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EditWorkoutClass($input: EditWorkoutClassInput!) {\n    editWorkoutClass(input: $input) {\n      id\n      title\n      date\n      workouts {\n        id\n        title\n        workoutType\n        workoutBlocks {\n          id\n          title\n          presets {\n            id\n            presetType\n            presetValue\n            exercise {\n              id\n            }\n          }\n        }\n      }\n      classAthletes {\n        id\n        name\n        member {\n          id\n          firstName\n          surname\n        }\n        memberID\n        workoutID\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation EditWorkoutClass($input: EditWorkoutClassInput!) {\n    editWorkoutClass(input: $input) {\n      id\n      title\n      date\n      workouts {\n        id\n        title\n        workoutType\n        workoutBlocks {\n          id\n          title\n          presets {\n            id\n            presetType\n            presetValue\n            exercise {\n              id\n            }\n          }\n        }\n      }\n      classAthletes {\n        id\n        name\n        member {\n          id\n          firstName\n          surname\n        }\n        memberID\n        workoutID\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MarkBlogEntryAsRead($input: MarkBlogEntryAsReadInput!) {\n    markBlogEntryAsRead(input: $input) {\n      id\n      isNew\n    }\n  }\n"): (typeof documents)["\n  mutation MarkBlogEntryAsRead($input: MarkBlogEntryAsReadInput!) {\n    markBlogEntryAsRead(input: $input) {\n      id\n      isNew\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadMyself {\n    readMyself {\n      id\n      firstName\n      surname\n      email\n      profileImageUrl\n      boxOwnership {\n        id\n        displayTitle\n        hasActiveSubscription\n      }\n      boxOperator {\n        id\n        displayTitle\n        hasActiveSubscription\n        requestedMembersAmount\n      }\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n      boxMemberships {\n        id\n        displayTitle\n      }\n      permissions\n      dashboardBadgeCount\n      streak {\n        id\n        numberOfDays\n        streakWeeks\n        upcomingStreakDays\n        upcomingStreakWeeks\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReadMyself {\n    readMyself {\n      id\n      firstName\n      surname\n      email\n      profileImageUrl\n      boxOwnership {\n        id\n        displayTitle\n        hasActiveSubscription\n      }\n      boxOperator {\n        id\n        displayTitle\n        hasActiveSubscription\n        requestedMembersAmount\n      }\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n      boxMemberships {\n        id\n        displayTitle\n      }\n      permissions\n      dashboardBadgeCount\n      streak {\n        id\n        numberOfDays\n        streakWeeks\n        upcomingStreakDays\n        upcomingStreakWeeks\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadExercises {\n    readExercises {\n      id\n      title\n      bestResult\n      lastTraining\n      type\n    }\n  }\n"): (typeof documents)["\n  query ReadExercises {\n    readExercises {\n      id\n      title\n      bestResult\n      lastTraining\n      type\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadOneExercise($input: ReadOneExerciseInput!) {\n    readOneExercise(input: $input) {\n      id\n      title\n      bestResult\n      lastTraining\n      type\n      exerciseResults {\n        ...ExerciseResultFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReadOneExercise($input: ReadOneExerciseInput!) {\n    readOneExercise(input: $input) {\n      id\n      title\n      bestResult\n      lastTraining\n      type\n      exerciseResults {\n        ...ExerciseResultFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadOneExerciseResult($input: ReadOneExerciseResultInput!) {\n    readOneExerciseResult(input: $input) {\n      ...ExerciseResultFragment\n    }\n  }\n"): (typeof documents)["\n  query ReadOneExerciseResult($input: ReadOneExerciseResultInput!) {\n    readOneExerciseResult(input: $input) {\n      ...ExerciseResultFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadWorkoutsByDate($input: ReadWorkoutsByDateInput!) {\n    readWorkoutsByDate(input: $input) {\n      id\n      public {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        canBeUsedForFineTuning\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        customer {\n          id\n          displayTitle\n        }\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n      private {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReadWorkoutsByDate($input: ReadWorkoutsByDateInput!) {\n    readWorkoutsByDate(input: $input) {\n      id\n      public {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        canBeUsedForFineTuning\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        customer {\n          id\n          displayTitle\n        }\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n      private {\n        id\n        title\n        description\n        date\n        workoutType\n        allowExerciseTracking\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadOneWorkout($input: ReadOneWorkoutInput!) {\n    readOneWorkout(input: $input) {\n      id\n      title\n      description\n      date\n      workoutType\n      allowExerciseTracking\n      measurementExercise {\n        id\n        title\n      }\n      measurementType\n      workoutBlocks {\n        ...WorkoutBlockFragment\n      }\n      teammates {\n        id\n        name\n        adoptionToken\n        editable\n        isMyself\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReadOneWorkout($input: ReadOneWorkoutInput!) {\n    readOneWorkout(input: $input) {\n      id\n      title\n      description\n      date\n      workoutType\n      allowExerciseTracking\n      measurementExercise {\n        id\n        title\n      }\n      measurementType\n      workoutBlocks {\n        ...WorkoutBlockFragment\n      }\n      teammates {\n        id\n        name\n        adoptionToken\n        editable\n        isMyself\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadOneWorkoutRanking($input: ReadOneWorkoutInput!) {\n    readOneWorkout(input: $input) {\n      id\n      title\n      date\n      workoutType\n      allowExerciseTracking\n      boxRanking {\n        position\n        member {\n          id\n          firstName\n          surname\n          profileImageUrl\n        }\n        athlet {\n          id\n          exerciseResults {\n            ...ExerciseResultFragment\n          }\n        }\n        score\n      }\n      workoutBlocks {\n        id\n        title\n        presets {\n          id\n\n          presetType\n          presetValue\n          variant\n          exercise {\n            id\n            title\n          }\n\n          boxRanking {\n            position\n            member {\n              id\n              firstName\n              surname\n              profileImageUrl\n            }\n            score\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReadOneWorkoutRanking($input: ReadOneWorkoutInput!) {\n    readOneWorkout(input: $input) {\n      id\n      title\n      date\n      workoutType\n      allowExerciseTracking\n      boxRanking {\n        position\n        member {\n          id\n          firstName\n          surname\n          profileImageUrl\n        }\n        athlet {\n          id\n          exerciseResults {\n            ...ExerciseResultFragment\n          }\n        }\n        score\n      }\n      workoutBlocks {\n        id\n        title\n        presets {\n          id\n\n          presetType\n          presetValue\n          variant\n          exercise {\n            id\n            title\n          }\n\n          boxRanking {\n            position\n            member {\n              id\n              firstName\n              surname\n              profileImageUrl\n            }\n            score\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadOneAthlet($input: ReadOneAthletInput!) {\n    readOneAthlet(input: $input) {\n      id\n      name\n      adoptionToken\n      editable\n      exerciseResults {\n        ...ExerciseResultFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReadOneAthlet($input: ReadOneAthletInput!) {\n    readOneAthlet(input: $input) {\n      id\n      name\n      adoptionToken\n      editable\n      exerciseResults {\n        ...ExerciseResultFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadCustomer {\n    readCustomer {\n      id\n      displayTitle\n      description\n    }\n  }\n"): (typeof documents)["\n  query ReadCustomer {\n    readCustomer {\n      id\n      displayTitle\n      description\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadBoxOwnership {\n    readBoxOwnership {\n      id\n      displayTitle\n      hasActiveSubscription\n      confirmedMembers {\n        id\n        firstName\n        surname\n        profileImageUrl\n        latestActivity\n        latestBoxActivity\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n        profileImageUrl\n        latestActivity\n        latestBoxActivity\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReadBoxOwnership {\n    readBoxOwnership {\n      id\n      displayTitle\n      hasActiveSubscription\n      confirmedMembers {\n        id\n        firstName\n        surname\n        profileImageUrl\n        latestActivity\n        latestBoxActivity\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n        profileImageUrl\n        latestActivity\n        latestBoxActivity\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadPrices {\n    readPrices {\n      id\n      amount\n      period\n      title\n      description\n    }\n  }\n"): (typeof documents)["\n  query ReadPrices {\n    readPrices {\n      id\n      amount\n      period\n      title\n      description\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadContent($type: String!) {\n    readContent(type: $type)\n  }\n"): (typeof documents)["\n  query ReadContent($type: String!) {\n    readContent(type: $type)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadFaqItems {\n    readFaqItems {\n      id\n      title\n      content\n    }\n  }\n"): (typeof documents)["\n  query ReadFaqItems {\n    readFaqItems {\n      id\n      title\n      content\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadWorkoutClassesByDate($input: ReadWorkoutClassesByDateInput!) {\n    readWorkoutClassesByDate(input: $input) {\n      id\n      title\n      date\n    }\n  }\n"): (typeof documents)["\n  query ReadWorkoutClassesByDate($input: ReadWorkoutClassesByDateInput!) {\n    readWorkoutClassesByDate(input: $input) {\n      id\n      title\n      date\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadOneWorkoutClass($input: ReadOneWorkoutClassInput!) {\n    readOneWorkoutClass(input: $input) {\n      id\n      title\n      date\n      workouts {\n        id\n        title\n        workoutType\n        allowExerciseTracking\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n      potentialAthletes {\n        id\n        firstName\n        surname\n      }\n      classAthletes {\n        id\n        name\n        member {\n          id\n          firstName\n          surname\n          profileImageUrl\n        }\n        memberID\n        workoutID\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReadOneWorkoutClass($input: ReadOneWorkoutClassInput!) {\n    readOneWorkoutClass(input: $input) {\n      id\n      title\n      date\n      workouts {\n        id\n        title\n        workoutType\n        allowExerciseTracking\n        measurementExercise {\n          id\n          title\n        }\n        measurementType\n        workoutBlocks {\n          ...WorkoutBlockFragment\n        }\n      }\n      potentialAthletes {\n        id\n        firstName\n        surname\n      }\n      classAthletes {\n        id\n        name\n        member {\n          id\n          firstName\n          surname\n          profileImageUrl\n        }\n        memberID\n        workoutID\n        exerciseResults {\n          ...ExerciseResultFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadExistingResultsByWeek($input: ReadExistingResultsByWeekInput!) {\n    readExistingResultsByWeek(input: $input) {\n      date\n      hasResult\n    }\n  }\n"): (typeof documents)["\n  query ReadExistingResultsByWeek($input: ReadExistingResultsByWeekInput!) {\n    readExistingResultsByWeek(input: $input) {\n      date\n      hasResult\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadBlogEntries {\n    readBlogEntries {\n      id\n      creator {\n        id\n        firstName\n        surname\n        profileImageUrl\n      }\n      title\n      content\n      date\n      isNew\n    }\n  }\n"): (typeof documents)["\n  query ReadBlogEntries {\n    readBlogEntries {\n      id\n      creator {\n        id\n        firstName\n        surname\n        profileImageUrl\n      }\n      title\n      content\n      date\n      isNew\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;