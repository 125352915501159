import { useEffect, useState } from 'react'

export const classNames = (...classes: (string | boolean)[]) => {
  return classes.filter(Boolean).join(' ')
}

export const getDatesInRange = (startDate: Date, endDate: Date) => {
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export const useMediaQuery = (query: string) => {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches
    }
    return false
  }

  const [matches, setMatches] = useState<boolean>(getMatches(query))

  function handleChange() {
    setMatches(getMatches(query))
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query)

    // Triggered at the first client-side load and if query changes
    handleChange()

    // Listen matchMedia
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange)
    } else {
      matchMedia.addEventListener('change', handleChange)
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange)
      } else {
        matchMedia.removeEventListener('change', handleChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return matches
}

export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const variantName = (variant?: string | null) => {
  switch (variant) {
    case 'row':
      return 'Rudern'
    case 'c2bike':
      return 'Fahrrad'
    case 'echoBike':
      return 'Echo Bike'
    case 'assaultBike':
      return 'Assault Bike'
    case 'ski':
      return 'Ski'
    case 'run':
      return 'Laufen'
    default:
      return null
  }
}

export const workoutMeasurementName = (workoutMeasurement?: string | null) => {
  switch (workoutMeasurement) {
    case 'calories':
      return 'Kalorien'
    case 'distance':
      return 'Meter'
    case 'time':
      return 'Zeit'
    case 'roundsAndReps':
      return 'Runden & Wiederholungen'
    case 'reps':
      return 'Wiederholungen'
    default:
      return null
  }
}
