/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Link } from "react-router-dom";
import { useMyself } from "../_context/UserContext";
import { useBreadcrumb } from "../_features/Breadcrumbs";
import { classNames } from "../_helpers";
import { AppNav } from "./AppNav";
import { Header } from "./Header";

export const Layout = ({ children, noUser, hideBottomNav }: { children: React.ReactNode, noUser?: boolean, hideBottomNav?: boolean }) => {
  const { isDebugUser } = useMyself() || {}
  const { breadcrumbs } = useBreadcrumb() || {}

  return (
    <>
      <div className={classNames(
        'flex h-full bg-neutral-950 pb-safe',
        isDebugUser ? 'pt-7' : ''
      )}>
        {isDebugUser && (
          <div className={'fixed flex top-0 left-0 w-screen h-7 bg-red-600 items-center justify-center uppercase font-bold text-white tracking-widest'}>
            DEBUG-USER
          </div>
        )}

        <div id={'content'} className={classNames(
          'w-full',
          (breadcrumbs || []).length > 0 ? 'pt-[128px]' : 'pt-24',
          !noUser && 'pb-32 lg:pb-20'
        )}>
          <Header noUser={noUser} />

          {children}

          {noUser && (
            <div className={'relative flex items-center justify-center gap-12 text-neutral-400 py-8 z-10'}>
              <Link to={`/imprint`}>
                Impressum
              </Link>

              <Link to={`/privacy`}>
                Datenschutz
              </Link>

              <Link to={`/terms-of-service`}>
                AGB
              </Link>
            </div>
          )}
          <AppNav noUser={noUser || hideBottomNav} />
        </div>
      </div>
    </>
  )
}
