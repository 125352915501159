import React from "react";
import { classNames } from "../_helpers";

type PropTypes = {
  children: React.ReactNode
  variant?: 'outlined' | 'contained'
  type?: 'button' | 'submit' | 'reset'
  size?: 'small' | 'large' | 'default'
  onClick?: () => void
  disabled?: boolean
  fullWidth?: boolean
  autoFocus?: boolean
  color?: 'primary' | 'success' | 'error' | 'default'
  icon?: React.ReactNode
}

export const Button = ({children, type, disabled, onClick, fullWidth, autoFocus = false, color = 'default', size = 'default', icon, variant = 'outlined' }: PropTypes) => (
  <button
    autoFocus={autoFocus}
    onClick={onClick}
    className={classNames(
      'inline-flex items-center justify-center rounded-lg border border-transparent text-sm font-medium shadow-sm focus:outline-none focus:ring-1 gap-2',
      color === 'primary' ? 'bg-red-500 text-white hover:bg-red-500-300' : '',
      color === 'success' ? 'bg-lime-300 text-black ring-inset' : '',
      color === 'error' ? 'bg-red-500 text-white ring-inset' : '',
      color === 'default' ? 'text-white ring-inset ring-2 ring-sky-500' : '',
      color === 'default' && variant === 'contained' && 'bg-sky-500',
      size === 'default' ? 'py-3 px-4 text-sm font-medium' : '',
      size === 'small' ? 'px-2 py-1.5 text-xs' : '',
      size === 'large' ? 'px-6 py-3 text-lg' : '',
      'disabled:bg-gray-300 disabled:ring-0 disabled:cursor-not-allowed',

      fullWidth ? 'w-full' : false
    )}
    type={type}
    disabled={disabled}
  >
    {icon}
    {children}
  </button>
)
