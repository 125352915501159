import { HomeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useBreadcrumb } from "../_features/Breadcrumbs";

export const Breadcrumbs = () => {
  const { breadcrumbs } = useBreadcrumb();

  if (!breadcrumbs.length) {
    return null
  }

  return (
    <nav aria-label="Breadcrumb" className="flex h-10 border-y border-neutral-500 bg-neutral-600">
      <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 px-6 sm:px-6 lg:px-8">
        <li className="flex">
          <div className="flex items-center">
            <Link to="/" className="text-neutral-100 hover:text-white">
              <HomeIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0"/>
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {breadcrumbs.map(page => (
          <li key={page.label} className="flex">
            <div className="flex items-center">
              <svg
                fill="currentColor"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                aria-hidden="true"
                className="h-full w-5 flex-shrink-0 text-neutral-500"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"/>
              </svg>
              <Link
                to={page.path}
                className="ml-4 text-[13px] leading-tight text-neutral-200 hover:text-white"
              >
                {page.label}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
