import React from "react";
import { LoginFrame } from "../../_ui/LoginFrame";
import { useServiceWorker } from "../ServiceWorker";
import { SwUpdateDialog } from "../SwUpdateDialog";
import { LoginForm } from "./components/LoginForm";

export const Login = () => {
  const { isUpdateAvailable, updateAssets } = useServiceWorker() || {}

  return (
    <div className="flex min-h-screen h-full">
      <LoginFrame>
        <LoginForm />
      </LoginFrame>

      {isUpdateAvailable && updateAssets && (
        <SwUpdateDialog />
      )}
    </div>
  )
}
