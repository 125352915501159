import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import { useMyself } from "../../../_context/UserContext";
import { resetPasswordMutation } from "../../../_lib/graphql/mutations";
import { Button } from "../../../_ui/Button";
import { Passwordfield } from "../../../_ui/Forms/Passwordfield";
import { Textfield } from "../../../_ui/Forms/Textfield";
import { useSnackbarPush } from "../../SnackbarContext/SnackbarContext";

export const LoginForm = () => {
  const [showReset, setShowReset] = useState(false)
  const [resetEmail, setResetEmail] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const { pushSuccess, pushError } = useSnackbarPush()
  const { login } = useMyself() || {}

  const [state, executeResetPassword] = useMutation(resetPasswordMutation)

  const handleLogin = useCallback((e: React.FormEvent) => {
    e.preventDefault()
    if (login) {
      login(email, password, false)
    }
  }, [login, email, password])

  const handleReset = useCallback((e: React.FormEvent) => {
    e.preventDefault()

    executeResetPassword({
      input: {
        email: resetEmail
      }
    }).then(res => {
      if (res.error) {
        pushError('Passwort zurücksetzen fehlgeschlagen')
      } else {
        pushSuccess('Bitte prüfe deine E-Mails')
        navigate(`/reset/${res.data?.resetPassword}`)
      }
    })
  }, [resetEmail, executeResetPassword, pushSuccess, pushError, navigate])

  if (showReset) {
    return (
      <form onSubmit={handleReset} className="space-y-6">
        <button
          className={'flex items-center gap-2 text-neutral-300'}
          onClick={() => setShowReset(false)}
        >
          <ChevronLeftIcon className={'w-5 h-5'} />
          <span>Zurück zum Login</span>
        </button>

        <Textfield
          label={'E-Mail Adresse'}
          value={resetEmail}
          onChange={setResetEmail}
          required
          autoComplete={'email'}
          type={'email'}
        />
        <div>
          <Button
            fullWidth
            type={'submit'}
            variant={'contained'}
            disabled={state.fetching}
          >
            Password zurücksetzen
          </Button>
        </div>
      </form>
    )
  }

  return (
    <>
      <form onSubmit={handleLogin} className="space-y-6">
        <Textfield
          label={'E-Mail Adresse'}
          value={email}
          onChange={setEmail}
          required
          autoComplete={'email'}
          type={'email'}
        />

        <Passwordfield
          label={'Passwort'}
          value={password}
          onChange={setPassword}
        />

        <div className="flex items-center justify-between">
          <div className="text-sm">
            <div
              tabIndex={-1}
              className="font-medium text-neutral-400 hover:text-neutral-200 cursor-pointer"
              onClick={() => setShowReset(true)}
            >
              Passwort vergessen?
            </div>
          </div>
        </div>

        <div>
          <Button
            fullWidth
            type={'submit'}
            variant={'contained'}

          >
            Anmelden
          </Button>
        </div>
      </form>

      <p className="mt-6 text-center text-sm/6 text-neutral-500">
        Noch keinen Account?{' '}
        <Link tabIndex={-1} to={`/registration`} className="font-semibold text-sky-500 hover:text-sky-100 focus:ring-4">
          Jetzt registrieren
        </Link>
      </p>
    </>
  )
}
