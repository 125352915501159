import React, { Suspense, useEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { Provider as URQLProvider } from 'urql';
import { Snackbar } from "./_features";
import { NotFound } from "./_features/NotFound";
import { Websocket } from "./_features/Websocket";
import GraphQLClientSilverstripe from './_lib/graphql/URQLClientSilverstripe';
import { AppProtected } from "./AppProtected";
import { AppPublic } from "./AppPublic";
import { ScrollToTop } from "./ScrollToTop";

const BoxMembersPage = React.lazy(() => import(/* webpackChunkName: "boxMembersPage" */'./_pages/BoxMembersPage')
  .then(({ BoxMembersPage }) => ({default: BoxMembersPage}))
);
const DashboardPage = React.lazy(() => import(/* webpackChunkName: "dashboardPage" */'./_pages/DashboardPage')
  .then(({ DashboardPage }) => ({default: DashboardPage}))
);
const ExercisesPage = React.lazy(() => import(/* webpackChunkName: "exercisesPage" */'./_pages/ExercisesPage')
  .then(({ ExercisesPage }) => ({default: ExercisesPage}))
);
const ExerciseHistoryPage = React.lazy(() => import(/* webpackChunkName: "exerciseHistoryPage" */'./_pages/ExerciseHistoryPage')
  .then(({ ExerciseHistoryPage }) => ({default: ExerciseHistoryPage}))
);
const HomePage = React.lazy(() => import(/* webpackChunkName: "homePage" */'./_pages/HomePage')
  .then(({ HomePage }) => ({default: HomePage}))
);
const ImpressumPage = React.lazy(() => import(/* webpackChunkName: "impressumPage" */'./_pages/ImpressumPage')
  .then(({ ImpressumPage }) => ({default: ImpressumPage}))
);
const JoinBoxPage = React.lazy(() => import(/* webpackChunkName: "joinBoxPage" */'./_pages/JoinBoxPage')
  .then(({ JoinBoxPage }) => ({default: JoinBoxPage}))
);
const OnboardingPage = React.lazy(() => import(/* webpackChunkName: "onboardingPage" */'./_pages/OnboardingPage')
  .then(({ OnboardingPage }) => ({default: OnboardingPage}))
);
const PricesPage = React.lazy(() => import(/* webpackChunkName: "pricesPage" */'./_pages/PricesPage')
  .then(({ PricesPage }) => ({default: PricesPage}))
);
const PrivacyPage = React.lazy(() => import(/* webpackChunkName: "privacyPage" */'./_pages/PrivacyPage')
  .then(({ PrivacyPage }) => ({default: PrivacyPage}))
);
const ProfilePage = React.lazy(() => import(/* webpackChunkName: "profilePage" */'./_pages/ProfilePage')
  .then(({ ProfilePage }) => ({default: ProfilePage}))
);
const RegistrationPage = React.lazy(() => import(/* webpackChunkName: "registrationPage" */'./_pages/RegistrationPage')
  .then(({ RegistrationPage }) => ({default: RegistrationPage}))
);
const SetNewPasswordPage = React.lazy(() => import(/* webpackChunkName: "setNewPasswordPage" */'./_pages/SetNewPasswordPage')
  .then(({ SetNewPasswordPage }) => ({default: SetNewPasswordPage}))
);
const TermsOfServicePage = React.lazy(() => import(/* webpackChunkName: "termsOfServicePage" */'./_pages/TermsOfServicePage')
  .then(({ TermsOfServicePage }) => ({default: TermsOfServicePage}))
);
const WhiteboardPage = React.lazy(() => import(/* webpackChunkName: "whiteboardPage" */'./_pages/WhiteboardPage')
  .then(({ WhiteboardPage }) => ({default: WhiteboardPage}))
);
const WorkoutListPage = React.lazy(() => import(/* webpackChunkName: "workoutListPage" */'./_pages/WorkoutListPage')
  .then(({ WorkoutListPage }) => ({default: WorkoutListPage}))
);
const WorkoutPage = React.lazy(() => import(/* webpackChunkName: "workoutPage" */'./_pages/WorkoutPage')
  .then(({ WorkoutPage }) => ({default: WorkoutPage}))
);
const WorkoutResultPage = React.lazy(() => import(/* webpackChunkName: "workoutResultPage" */'./_pages/WorkoutResultPage')
  .then(({ WorkoutResultPage }) => ({default: WorkoutResultPage}))
);

const App = () => {
  useEffect(() => {
    const handleServiceWorkoutUpdate = async () => {
      if (typeof document !== 'object' || document.visibilityState === 'visible') {
        if ('serviceWorker' in navigator) {
          const registration = await navigator.serviceWorker.getRegistration();

          if (registration) {
            // Service Worker neu vom Server laden
            console.log('Prüfe auf neue Service Worker-Version...');
            await registration.update();

            if (registration.waiting) {
              console.log('Neuer Service Worker gefunden. Skip Waiting...');
              registration.waiting.postMessage({ type: 'SKIP_WAITING' });
              window.location.reload();
            } else {
              console.log('Kein neuer Service Worker verfügbar.');
            }
          } else {
            console.log('Kein Service Worker registriert.');
          }
        }
      }
    }

    window.addEventListener('visibilitychange', handleServiceWorkoutUpdate);

    return () => {
      window.removeEventListener('visibilitychange', handleServiceWorkoutUpdate);
    }
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Snackbar>
        <URQLProvider value={GraphQLClientSilverstripe}>
          <Websocket>
            <Suspense fallback={'loadingSpinner'}>
              <Routes>
                <Route path={'/'} element={<AppPublic />}>
                  <Route index element={<HomePage /> } />
                </Route>
                <Route path={'/imprint'} element={<AppPublic /> }>
                  <Route index element={<ImpressumPage /> } />
                </Route>
                <Route path={'/privacy'} element={<AppPublic /> }>
                  <Route index element={<PrivacyPage /> } />
                </Route>
                <Route path={'/terms-of-service'} element={<AppPublic /> }>
                  <Route index element={<TermsOfServicePage /> } />
                </Route>
                <Route path={'/prices'} element={<AppPublic /> }>
                  <Route index element={<PricesPage /> } />
                </Route>
                <Route path={'/registration/affiliate/:token'} element={<Outlet />}>
                  <Route index element={<RegistrationPage /> } />
                </Route>
                <Route path={'/registration'} element={<Outlet />}>
                  <Route index element={<RegistrationPage /> } />
                </Route>
                <Route path={'/reset/:resetToken'} element={<Outlet />}>
                  <Route index element={<SetNewPasswordPage /> } />
                </Route>
                <Route path={'/onboarding/join-box'} element={<AppProtected ignoreSubscription hideBottomNav />}>
                  <Route index element={<JoinBoxPage />} />
                </Route>
                <Route path={'/onboarding/:sessionID'} element={<AppProtected ignoreSubscription hideBottomNav />}>
                  <Route index element={<OnboardingPage />} />
                </Route>

                <Route path={'/dashboard'} element={<AppProtected />}>
                  <Route index element={<DashboardPage />} />
                </Route>
                <Route path={'/profile'} element={<AppProtected />}>
                  <Route index element={<ProfilePage />} />
                </Route>
                <Route path={'/whiteboard/:workoutClassID'} element={<AppProtected />}>
                  <Route index element={<WhiteboardPage />} />
                </Route>
                <Route path={'/whiteboard'} element={<AppProtected />}>
                  <Route index element={<WhiteboardPage />} />
                </Route>


                <Route path={'/workout/:year/:week/:day/:workoutID/results/:presetID'} element={<AppProtected />}>
                  <Route index element={<WorkoutResultPage />} />
                </Route>
                <Route path={'/workout/:year/:week/:day/:workoutID/results'} element={<AppProtected />}>
                  <Route index element={<WorkoutResultPage />} />
                </Route>
                <Route path={'/workout/:year/:week/:day/:workoutID'} element={<AppProtected />}>
                  <Route index element={<WorkoutPage />} />
                </Route>
                <Route path={'/workout/:year/:week/:day'} element={<AppProtected />}>
                  <Route index element={<WorkoutListPage />} />
                </Route>
                <Route path={'/workout/:year/:week'} element={<AppProtected />}>
                  <Route index element={<WorkoutListPage />} />
                </Route>
                <Route path={'/workout'} element={<AppProtected />}>
                  <Route index element={<WorkoutListPage />} />
                </Route>


                <Route path={'/exercises/:exerciseID'} element={<AppProtected />}>
                  <Route index element={<ExerciseHistoryPage />} />
                </Route>
                <Route path={'/exercises'} element={<AppProtected />}>
                  <Route index element={<ExercisesPage />} />
                </Route>
                <Route path={'/box-members'} element={<AppProtected />}>
                  <Route index element={<BoxMembersPage />} />
                </Route>
                <Route path={'*'} element={<AppProtected />}>
                  <Route index element={<NotFound />} />
                </Route>
              </Routes>
            </Suspense>
          </Websocket>
        </URQLProvider>
      </Snackbar>
    </BrowserRouter>
  );
}

export default App;
