import { graphql } from "../../../gql";


export const readMyselfQuery = graphql(`
  query ReadMyself {
    readMyself {
      id
      firstName
      surname
      email
      profileImageUrl
      boxOwnership {
        id
        displayTitle
        hasActiveSubscription
      }
      boxOperator {
        id
        displayTitle
        hasActiveSubscription
        requestedMembersAmount
      }
      hasActiveSubscription
      hasBoxMembership
      hasPendingBoxMembership
      boxMemberships {
        id
        displayTitle
      }
      permissions
      dashboardBadgeCount
      streak {
        id
        numberOfDays
        streakWeeks
        upcomingStreakDays
        upcomingStreakWeeks
      }
    }
  }
`);

export const readExercisesQuery = graphql(`
  query ReadExercises {
    readExercises {
      id
      title
      bestResult
      lastTraining
      type
    }
  }
`);

export const readOneExerciseQuery = graphql(`
  query ReadOneExercise($input: ReadOneExerciseInput!) {
    readOneExercise(input: $input) {
      id
      title
      bestResult
      lastTraining
      type
      exerciseResults {
        ...ExerciseResultFragment
      }
    }
  }
`);

export const readOneExerciseResultQuery = graphql(`
  query ReadOneExerciseResult($input: ReadOneExerciseResultInput!) {
    readOneExerciseResult(input: $input) {
      ...ExerciseResultFragment
    }
  }
`);

export const readWorkoutsByDateQuery = graphql(`
  query ReadWorkoutsByDate($input: ReadWorkoutsByDateInput!) {
    readWorkoutsByDate(input: $input) {
      id
      public {
        id
        title
        description
        date
        workoutType
        allowExerciseTracking
        canBeUsedForFineTuning
        measurementExercise {
          id
          title
        }
        measurementType
        customer {
          id
          displayTitle
        }
        workoutBlocks {
          ...WorkoutBlockFragment
        }
      }
      private {
        id
        title
        description
        date
        workoutType
        allowExerciseTracking
        measurementExercise {
          id
          title
        }
        measurementType
        workoutBlocks {
          ...WorkoutBlockFragment
        }
      }
    }
  }
`);

export const readOneWorkoutQuery = graphql(`
  query ReadOneWorkout($input: ReadOneWorkoutInput!) {
    readOneWorkout(input: $input) {
      id
      title
      description
      date
      workoutType
      allowExerciseTracking
      measurementExercise {
        id
        title
      }
      measurementType
      workoutBlocks {
        ...WorkoutBlockFragment
      }
      teammates {
        id
        name
        adoptionToken
        editable
        isMyself
        exerciseResults {
          ...ExerciseResultFragment
        }
      }
    }
  }
`);

export const readOneWorkoutRankingQuery = graphql(`
  query ReadOneWorkoutRanking($input: ReadOneWorkoutInput!) {
    readOneWorkout(input: $input) {
      id
      title
      date
      workoutType
      allowExerciseTracking
      boxRanking {
        position
        member {
          id
          firstName
          surname
          profileImageUrl
        }
        athlet {
          id
          exerciseResults {
            ...ExerciseResultFragment
          }
        }
        score
      }
      workoutBlocks {
        id
        title
        presets {
          id

          presetType
          presetValue
          variant
          exercise {
            id
            title
          }

          boxRanking {
            position
            member {
              id
              firstName
              surname
              profileImageUrl
            }
            score
          }
        }
      }
    }
  }
`);

export const readOneAthleteQuery = graphql(`
  query ReadOneAthlet($input: ReadOneAthletInput!) {
    readOneAthlet(input: $input) {
      id
      name
      adoptionToken
      editable
      exerciseResults {
        ...ExerciseResultFragment
      }
    }
  }
`)

export const readCustomerQuery = graphql(`
  query ReadCustomer {
    readCustomer {
      id
      displayTitle
      description
    }
  }
`);

export const readBoxOwnershipQuery = graphql(`
  query ReadBoxOwnership {
    readBoxOwnership {
      id
      displayTitle
      hasActiveSubscription
      confirmedMembers {
        id
        firstName
        surname
        profileImageUrl
        latestActivity
        latestBoxActivity
      }
      requestedMembers {
        id
        firstName
        surname
        profileImageUrl
        latestActivity
        latestBoxActivity
      }
    }
  }
`);

export const readPricesQuery = graphql(`
  query ReadPrices {
    readPrices {
      id
      amount
      period
      title
      description
    }
  }
`);

export const readContentQuery = graphql(`
  query ReadContent($type: String!) {
    readContent(type: $type)
  }
`);

export const readFaqItemsQuery = graphql(`
  query ReadFaqItems {
    readFaqItems {
      id
      title
      content
    }
  }
`);

export const readWorkoutClassesByDateQuery = graphql(`
  query ReadWorkoutClassesByDate($input: ReadWorkoutClassesByDateInput!) {
    readWorkoutClassesByDate(input: $input) {
      id
      title
      date
    }
  }
`);

export const readOneWorkoutClassQuery = graphql(`
  query ReadOneWorkoutClass($input: ReadOneWorkoutClassInput!) {
    readOneWorkoutClass(input: $input) {
      id
      title
      date
      workouts {
        id
        title
        workoutType
        allowExerciseTracking
        measurementExercise {
          id
          title
        }
        measurementType
        workoutBlocks {
          ...WorkoutBlockFragment
        }
      }
      potentialAthletes {
        id
        firstName
        surname
      }
      classAthletes {
        id
        name
        member {
          id
          firstName
          surname
          profileImageUrl
        }
        memberID
        workoutID
        exerciseResults {
          ...ExerciseResultFragment
        }
      }
    }
  }
`);

export const readExistingResultsByWeekQuery = graphql(`
  query ReadExistingResultsByWeek($input: ReadExistingResultsByWeekInput!) {
    readExistingResultsByWeek(input: $input) {
      date
      hasResult
    }
  }
`)

export const readBlogEntriesQuery = graphql(`
  query ReadBlogEntries {
    readBlogEntries {
      id
      creator {
        id
        firstName
        surname
        profileImageUrl
      }
      title
      content
      date
      isNew
    }
  }
`)
