import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  ArrowDownTrayIcon,
  ArrowLeftStartOnRectangleIcon,
  ArrowPathIcon,
  InformationCircleIcon,
  PencilIcon,
  QrCodeIcon,
  ShieldCheckIcon
} from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import versionJson from '../../app/_constants/version.json';
import { useMyself } from "../_context/UserContext";
import { AdoptAthletDialog } from "../_features/AdoptAthletDialog";
import { ImportWodifyDialog } from "../_features/ImportWodifyDialog";

export const UserNav = () => {
  const navigate = useNavigate()
  const [wodifyImportOpen, setWodifyImportOpen] = useState(false)
  const [qrImportOpen, setQrImportOpen] = useState(false)
  const { firstName, surname, profileImageUrl, hasActiveSubscription, hasBoxMembership, logout } = useMyself() || {};

  const initials = useMemo(() => {
    return `${firstName?.slice(0, 1) || ''}${surname?.slice(0, 1) || ''}`
  }, [firstName, surname])

  return (
    <>
      <Menu>
        <MenuButton>
          <div
            className={'flex items-center justify-center w-10 h-10 rounded-full bg-neutral-200 text-neutral-800 text-md'}>
            {profileImageUrl ? (
              <img src={profileImageUrl} alt="Profilbild" className="object-cover w-full h-full rounded-full"/>
            ) : initials}
          </div>
        </MenuButton>
        <MenuItems
          transition
          anchor="bottom end"
          className="w-52 origin-top-right mt-4 z-[81] divide-y divide-neutral-400/30 rounded-lg border border-neutral-400/30 bg-black/60 backdrop-blur p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
        >
          <MenuItem>
            <button
              onClick={() => window.location.reload()}
              className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
            >
              Neu laden
              <ArrowPathIcon className="w-5 h-5 ml-auto fill-white/30"/>
            </button>
          </MenuItem>

          <MenuItem>
            <button
              onClick={() => navigate(`/profile`)}
              className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
            >
              Profil bearbeiten
              <PencilIcon className="w-5 h-5 ml-auto fill-white/30"/>
            </button>
          </MenuItem>

          {(hasActiveSubscription || hasBoxMembership) && (
            <>
              <MenuItem>
                <button
                  onClick={() => setQrImportOpen(true)}
                  className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
                >
                  QR-Code scannen
                  <QrCodeIcon className="w-5 h-5 ml-auto fill-white/30"/>
                </button>
              </MenuItem>

              <MenuItem>
                <button
                  onClick={() => setWodifyImportOpen(true)}
                  className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
                >
                  Wodify import
                  <ArrowDownTrayIcon className="w-5 h-5 ml-auto fill-white/30"/>
                </button>
              </MenuItem>
            </>
          )}
          <MenuItem>
            <button
              onClick={() => navigate(`/privacy`)}
              className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
            >
              Datenschutz
              <InformationCircleIcon className="w-5 h-5 ml-auto fill-white/30"/>
            </button>
          </MenuItem>
          <MenuItem>
            <button
              onClick={() => navigate(`/imprint`)}
              className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
            >
              Impressum
              <InformationCircleIcon className="w-5 h-5 ml-auto fill-white/30"/>
            </button>
          </MenuItem>
          <MenuItem>
            <button
              onClick={() => navigate(`/terms-of-service`)}
              className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
            >
              AGB
              <ShieldCheckIcon className="w-5 h-5 ml-auto fill-white/30"/>
            </button>
          </MenuItem>
          <MenuItem>
            <button
              onClick={logout}
              className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
            >
              Abmelden
              <ArrowLeftStartOnRectangleIcon className="w-5 h-5 ml-auto fill-white/30"/>
            </button>
          </MenuItem>
          <MenuItem>
            <span className="block text-center py-2 px-3 text-xs text-neutral-600"
            >
              Version: {versionJson.version}
            </span>
          </MenuItem>
        </MenuItems>
      </Menu>

      <AdoptAthletDialog
        isOpen={qrImportOpen}
        onClose={() => setQrImportOpen(false)}
      />

      <ImportWodifyDialog
        isOpen={wodifyImportOpen}
        onClose={() => setWodifyImportOpen(false)}
      />
    </>
  )
}
