import { graphql } from "../../../gql";

export const registerMemberMutation = graphql(`
  mutation RegisterMember($input: RegisterMemberInput!) {
    registerMember(input: $input)
  }
`);

export const setNewPasswordMutation = graphql(`
  mutation SetNewPassword($input: SetNewPasswordInput!) {
    setNewPassword(input: $input) {
      id
      access_token
      refresh_token
      expiry
    }
  }
`);

export const loginUserMutation = graphql(`
  mutation LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      id
      access_token
      refresh_token
      expiry
    }
  }
`);

export const refreshTokenMutation = graphql(`
  mutation RefreshToken($refresh_token: String!) {
    refreshToken(refresh_token: $refresh_token) {
      id
      access_token
      refresh_token
      expiry
    }
  }
`)

export const logoutUserMutation = graphql(`
  mutation LogoutUser {
    logoutUser
  }
`);

export const updateProfileImageMutation = graphql(`
  mutation UpdateProfileImage($input: UpdateProfileImageInput!) {
    updateProfileImage(input: $input) {
      id
      profileImageUrl
    }
  }
`)

export const addResultMutation = graphql(`
  mutation AddResult($input: AddResultInput!) {
    addResult(input: $input) {
      exerciseResult {
        ...ExerciseResultFragment
      }
      athlet {
        id
        exerciseResults {
          ...ExerciseResultFragment
        }
      }
    }
  }
`);

export const deleteResultMutation = graphql(`
  mutation DeleteResult($input: DeleteResultInput!) {
    deleteResult(input: $input) {
      id
      bestResult
      lastTraining
      exerciseResults {
        ...ExerciseResultFragment
      }
    }
  }
`);

export const addAthletMutation = graphql(`
  mutation AddAthlet($input: AddAthletInput!) {
    addAthlet(input: $input) {
      id
      teammates {
        id
        name
        editable
      }
    }
  }
`);

export const createCheckoutSessionMutation = graphql(`
  mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {
    createCheckoutSession(input: $input)
  }
`);

export const createBoxCheckoutSessionMutation = graphql(`
  mutation CreateBoxCheckoutSession {
    createBoxCheckoutSession
  }
`);

export const createBillingPortalSessionMutation = graphql(`
  mutation CreateBillingPortalSession {
    createBillingPortalSession
  }
`);

export const createBoxBillingPortalSessionMutation = graphql(`
  mutation CreateBoxBillingPortalSession {
    createBoxBillingPortalSession
  }
`);

export const updateSubscriptionStatusMutation = graphql(`
  mutation UpdateSubscriptionStatus {
    updateSubscriptionStatus {
      id
      hasActiveSubscription
      hasBoxMembership
      hasPendingBoxMembership
      boxOwnership {
        id
        hasActiveSubscription
      }
    }
  }
`);

export const joinBoxMutation = graphql(`
  mutation JoinBox($input: JoinBoxInput!) {
    joinBox(input: $input) {
      id
      hasActiveSubscription
      hasBoxMembership
      hasPendingBoxMembership
    }
  }
`);

export const confirmBoxMembershipMutation = graphql(`
  mutation ConfirmBoxMembership($input: ConfirmBoxMembershipInput!) {
    confirmBoxMembership(input: $input) {
      id
      displayTitle
      requestedMembersAmount
      confirmedMembers {
        id
        firstName
        surname
      }
      requestedMembers {
        id
        firstName
        surname
      }
    }
  }
`);

export const cancelBoxMembershipMutation = graphql(`
  mutation CancelBoxMembership($input: CancelBoxMembershipInput!) {
    cancelBoxMembership(input: $input) {
      id
      displayTitle
      requestedMembersAmount
      confirmedMembers {
        id
        firstName
        surname
      }
      requestedMembers {
        id
        firstName
        surname
      }
    }
  }
`);

export const addWorkoutMutation = graphql(`
  mutation AddWorkout($input: AddWorkoutInput!) {
    addWorkout(input: $input) {
      id
      public {
        id
        title
        description
        date
        workoutType
        allowExerciseTracking
        measurementType
        measurementExercise {
          id
        }
        customer {
          id
          title
        }
        workoutBlocks {
          ...WorkoutBlockFragment
        }
      }
      private {
        id
        title
        description
        date
        workoutType
        allowExerciseTracking
        measurementType
        measurementExercise {
          id
        }
        workoutBlocks {
          ...WorkoutBlockFragment
        }
      }
    }
  }
`);

export const addWorkoutToAiFineTuningMutation = graphql(`
  mutation AddWorkoutToAiFineTuning($input: AddWorkoutInput!) {
    addWorkoutToAiFineTuning(input: $input) {
      id
      canBeUsedForFineTuning
    }
  }
`);

export const addAiWorkoutMutation = graphql(`
  mutation AddAiWorkout($input: AddAiWorkoutInput!) {
    addAiWorkout(input: $input) {
      id
      public {
        id
        title
        description
        date
        allowExerciseTracking
        canBeUsedForFineTuning
        measurementExercise {
          id
          title
        }
        measurementType
        customer {
          id
          title
        }
        workoutBlocks {
          ...WorkoutBlockFragment
        }
      }
      private {
        id
        title
        description
        date
        workoutType
        allowExerciseTracking
        measurementExercise {
          id
          title
        }
        measurementType
        workoutBlocks {
          ...WorkoutBlockFragment
        }
      }
    }
  }
`);

export const deleteWorkoutMutation = graphql(`
  mutation DeleteWorkout($input: DeleteWorkoutInput!) {
    deleteWorkout(input: $input) {
      id
      public {
        id
      }
      private {
        id
      }
    }
  }
`);

export const resetPasswordMutation = graphql(`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`);

export const adoptAthletMutation = graphql(`
  mutation AdoptAthlet($input: AdoptAthletInput!) {
    adoptAthlet(input: $input) {
      id
      date
      workoutBlocks {
        id
        presets {
          id
        }
      }
      teammates {
        id
        name
        adoptionToken
        editable
        exerciseResults {
          ...ExerciseResultFragment
        }
      }
    }
  }
`);

export const createClassParticipationMutation = graphql(`
  mutation CreateClassParticipation($input: CreateClassParticipationInput!) {
    createClassParticipation(input: $input) {
      message
      workoutClass {
        id
        potentialAthletes {
          id
          firstName
          surname
        }
        classAthletes {
          id
          name
          memberID
          workoutID
          member {
            id
            firstName
            surname
          }
          exerciseResults {
            ...ExerciseResultFragment
          }
        }
      }
    }
  }
`);

export const cancelClassParticipationMutation = graphql(`
  mutation CancelClassParticipation($input: CancelClassParticipationInput!) {
    cancelClassParticipation(input: $input) {
      id
      potentialAthletes {
        id
        firstName
        surname
      }
      classAthletes {
        id
      }
    }
  }
`);

export const startWorkoutClassMutation = graphql(`
  mutation StartWorkoutClass($input: StartWorkoutClassInput!) {
    startWorkoutClass(input: $input) {
      id
      title
      date
    }
  }
`);

export const editWorkoutClassMutation = graphql(`
  mutation EditWorkoutClass($input: EditWorkoutClassInput!) {
    editWorkoutClass(input: $input) {
      id
      title
      date
      workouts {
        id
        title
        workoutType
        workoutBlocks {
          id
          title
          presets {
            id
            presetType
            presetValue
            exercise {
              id
            }
          }
        }
      }
      classAthletes {
        id
        name
        member {
          id
          firstName
          surname
        }
        memberID
        workoutID
        exerciseResults {
          ...ExerciseResultFragment
        }
      }
    }
  }
`);

export const markBlogEntryAsReadMutation = graphql(`
  mutation MarkBlogEntryAsRead($input: MarkBlogEntryAsReadInput!) {
    markBlogEntryAsRead(input: $input) {
      id
      isNew
    }
  }
`);
