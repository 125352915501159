export const REACT_MOUNT = 'app-mount';

const hostname = window.location.hostname
let currentHost = ''
switch (hostname) {
  // live
  case 'test.tracktherep.de':
    currentHost = 'https://admin.staging.tracktherep.de'
    break
  case 'www.tracktherep.de':
  case 'tracktherep.de':
    currentHost = 'https://admin.tracktherep.de'
    break
  // dev
  default:
    currentHost = ['4443', '3000'].includes(window.location.port)
      ? 'http://track-the-rep.docker:8086'
      : 'http://track-the-rep.docker:8086'
}

export const IMPORT_ENDPOINT = `${currentHost}/api/wodify/import`

export const GRAPHQL_ENDPOINT = process.env.NODE_ENV === 'development'
  ? `${currentHost}/tracker`
  : `${currentHost}/tracker`

export const STRIPE_PLUBLIC_KEY = process.env.NODE_ENV === 'development'
  ? 'pk_test_51OluGBC1ARpekMtwMAufJva7TSjKbQlkTzXlnszvmUc4Figof3T4lM1mznmWWBOCYnvyhZOwUftZVjIErCyKrZDt00wT8K344g'
  : 'pk_live_51OluGBC1ARpekMtwCtXVSILjiyIzw9YpCCtTNsWVPMoXyTG49DYOkftACSpXEb7npQ3aTyQcRMaebe7RIJuJyjkS00eZjDqvo0'

const oAuthApiEndpoint = `${currentHost}/oauth2`
export const SILVERSTRIPE_OAUTH_API = {
  urls: {
    authorize: oAuthApiEndpoint + '/token/',
    refresh: oAuthApiEndpoint + '/refresh/'
  },
  data: {
    authorize: {
      grant_type: 'password'
    },
    refresh: {
      grant_type: 'refresh_token'
    }
  }
}

