import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

type PropType = {
  label: string
  name?: string
  autoComplete?: string
  value: string
  onChange: (value: string) => void
  required?: boolean
}

export const Passwordfield = ({ label, name, autoComplete, value, onChange, required = false }: PropType) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <label className="block w-full space-y-1">
      <span className="block text-sm font-medium text-neutral-300">
        {label}
      </span>
      <div className="grid grid-cols-[1fr,40px] mt-1">
        <input
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          required={required}
          value={value}
          onChange={e => onChange(e.target.value)}
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-sky-500 sm:text-sm"
        />
        <button
          tabIndex={-1}
          type={"button"}
          onClick={() => setShowPassword(prev => !prev)}
          className={'flex items-center justify-center text-white'}
        >
          {showPassword ? (
            <EyeSlashIcon className={'w-4 h-4'}/>
          ) : (
            <EyeIcon className={'w-4 h-4'}/>
          )}
        </button>
      </div>
    </label>
  )
}
